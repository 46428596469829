.modal-styles {
    width: 1000px;
    height: 600;
}

.styled-div {
    border-radius: 5px;
    padding: 0px 3px;
    background-color: white;
    border: 1px solid red;
}