html {
  overflow-x: hidden;
  overflow-y: hidden;
}

.homepage {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
}

.top-section {
  display: flex;
  width: 100%;
  height: 80vh;
  flex-grow: 1;
  overflow-y: scroll;
}

.infosection-wrapper {
  margin: 28px;
  width: 70%;
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.textMapCombo {
  height: calc(35vh);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.periodText {
  width: 50%;
  padding: 10px 16px;
  border-radius: 4px;
  overflow-y: scroll;
}

.infosection-graph {
  width: 100%;
  min-height: 200px;
  /* height: 300px; */
}

.jumbotron {
  padding: 15px;
  width: 30%;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.15);
  height: fit-content;
  border-bottom-right-radius: 10px;
  min-height: 85vh;
}

#gotohydeportal:hover {
  cursor: pointer;
  opacity: 0.8;
}

.timeline-overlay {
  width: 100%;
  position: relative;
  bottom: 0;
  background-color: white;
}

table {
  border-collapse: collapse;
  margin: 10px 0;
  font-size: 0.9em;
  min-width: 300px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  overflow: hidden;
}

table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
}

table th,
table td {
  padding: 4px 8px;
}

table tbody tr {
  border-bottom: thin solid #dddddd;
}

table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.jumbotron a {
  color: #fff;
}


$globe-dimension: 300px;

.hydeportal-globe {
  width: $globe-dimension;
  height: $globe-dimension;
  overflow: hidden;
  border-radius: 100%;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  transition: all 0.2s ease-in-out;
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  }
}

.click-me-globe {
  position: absolute;
  bottom: -30;
  color: black;
  font-size: 12px;
}
.relative {
  position: relative;
}
.hydeportal-globe img {
  transform: translateX(-45%);
  transition: all 0.2s ease-in-out;
  height: calc(2 * $globe-dimension);
  &:hover {
    height: calc(2.5 * $globe-dimension);
  }
}

.click-for-portal {
  font-size: 20px;
  font-weight: 600;
  height: 30px;
  margin-bottom: 10px;
  // transform: rotate(-90deg);
}

.hydeportal-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

a, b, strong, u {
  font-size: inherit;
}