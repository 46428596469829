.timeline {
  border-top: 1px solid black;
  display: inline-flex;
  width: 100%;
  overflow-x: scroll;
  bottom: 0;
  overflow-y: hidden;
}

.icon {
  width: 48px;
  height: 48px;
  
}

.timelineObject {
  flex-shrink: 0;
  padding-top: 32px;
  padding-bottom: 32px;
  width: 300px;
  padding-left: 18px;
  padding-right: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.timelineObject:hover {
  cursor: pointer;
}

.relative {
  position: relative;
}

#pin {
  width: 40px;
  position: absolute;
  left: calc(50% - 20px);
  top: -20px;
}
